import { Pipe, PipeTransform } from '@angular/core';
import { getExtensionFromFilename } from '@shared/helpers/files.helpers';

@Pipe({
  name: 'clearFilename',
  standalone: true,
})
export class ClearFilenamePipe implements PipeTransform {

  /**
   * Clear filename
   */
  transform(filename: string): string {
    const extension = getExtensionFromFilename(filename);
    const timestamp = filename.split('-').pop();
    const clearFilename = filename.replace(`-${timestamp}`, '');

    return `${clearFilename}.${extension}`;
  }

}
